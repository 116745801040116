<!-- 待缴费 -->
<template>
  <div id="electricityFee">
    <div>
      <!-- 头部地理位置和搜索框 -->
      <div class="head">
        <!-- 顶部NavBar 开始 -->
        <van-nav-bar
          :title="topTitle"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div id="listLoading">
      <van-loading
        v-if="loading"
        type="spinner"
      />
    </div>
    <div class="outboxstyle">
      <div v-for="(item, index) in paymentList" :key="index">
        <van-cell-group :class="[item.show?'group':'group2']" @click="choose(item)">
          <img :src="item.show==true?imageL[1] : imageL[2]" class="line">
          <div class="total">
            <img :src="imgList[0]">
            <div class="total-text">
              <span class="total-title">{{ item.projectName + '-' + item.chargeName }}</span>
              <span class="total-status">{{ item.mustSpan }}</span>
            </div>
          </div>
          <van-cell :title="$t('pay.tobe-pay')" :value="'¥'+fenToYuan(item.payamt)" title-style="color: #747474" />
          <van-cell :title="$t('pay.unpaid-amount')" :value="'¥'+fenToYuan(item.unpaidamt)" title-style="color: #747474" />
          <van-cell :title="$t('pay.full-payment')" title-style="color: #747474">
            <van-switch
              v-model="item.fullPay"
              size="24px"
              :disabled="item.payLimit === '-1' || item.payLimit === '0' || item.payLimit === -1 || item.payLimit === 0 || item.payLimit === null"
              @click="check(item, index)"
            />
          </van-cell>
          <!-- <van-cell id="totalpay" title="缴费金额" :value="'¥'+fenToYuan(item.payNowStr)" /> -->
          <van-cell :title="$t('pay.payment-amount')" title-style="color: #747474">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #default>
              <span v-if="item.fullPay" style="color:#3BA4F2">¥{{ fenToYuan(item.payNowStr) }}</span>
              <span v-if="!item.fullPay" style="color:#3BA4F2">¥{{ item.payNowStr }}</span>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <div class="bottom">
      <van-tag v-if="count !== 0" class="round-tag" type="danger" round>{{ count }}</van-tag>
      <van-button v-if="total === 0" type="info" size="large" :loading="nextLoading" class="bottombutton" @click="nextStep">{{ $t('main.next') }}</van-button>
      <van-button v-if="total !== 0" type="info" size="large" :loading="nextLoading" class="bottombutton" @click="nextStep">{{ $t('main.next') }}（￥{{ total }}）</van-button>
    </div>
    <van-dialog v-model="show" title="修改缴费金额" show-cancel-button :before-close="chargeBtn">
      <!-- 允许输入正整数，调起纯数字键盘 -->
      <van-field v-model="money" style="width: 90%;margin: 20px;border: 1px solid #eee;" type="number" />
    </van-dialog>
    <!-- 回到顶部按钮 -->
    <v-top />
  </div>
</template>

<script>
// import { pageListReiForm } from '@/api/reiform'
import { submitNextStep } from '@/api/waitingPay'
import { saveUserGetStandNameList, closeOrderByIdserialAndProjectCode } from '@/api/project'
import { fenToYuan } from '@/utils/commonUtils'
// import moment from 'moment/moment'
import { Dialog } from 'vant'
import { Toast } from 'vant'
export default {
  name: 'Project',
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast
  },
  data() {
    return {
      value: '',
      showPicker: false,
      conheight: {
        height: '',
        width: '100%',
        overflow: 'auto',
        // background: "#EFF6FF"
        background: 'transparent'
      },
      checked: false,
      imgList: [
        require('../../../assets/newImages/icon4.png'),
        require('../../../assets/newImages/icon6.png'),
        require('../../../assets/newImages/orderList4.png')

      ],
      imageL: [
        require('../../../assets/newImages/home_icon6.png'),
        require('../../../assets/newImages/right.png'),
        require('../../../assets/newImages/rightbefore.png')
      ],
      paymentList: '',
      total: 0,
      show: false,
      money: '',
      moneyindex: '',
      count: 0,
      nextLoading: false,
      sectionAmount: 0,
      allAmount: 0,
      loading: false,
      topTitle: '',
      projectIdList: []
    }
  },
  watch: {
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  created() {
  },
  mounted() {
    // 拼装页面项目名称
    this.topTitle = new Date().getFullYear() + '收费项目'
    this.getWaitingPayList()
    this.calculationData()
  },
  methods: {
    choose(obj) {
      if (obj.mustPay === '1' || obj.mustPay === 1) {
        return
      }
      obj.show = !obj.show
      this.calculationData()
      return obj
    },
    check(obj, index) {
      if (obj.payLimit === '-1' || obj.payLimit === '0' || obj.payLimit === -1 || obj.payLimit === 0 || obj.payLimit === null) {
        Toast.fail('该缴费项目不允许部分缴费')
        return
      }
      // 阻止事件穿透
      event.stopPropagation()
      if (obj.fullPay === true) {
        this.show = true
        // 获取当前数据的未缴金额
        this.money = this.fenToYuan(obj.unpaidamt)
        // 获取当前数据的index
        this.moneyindex = index
        this.calculationData()
      } else {
        obj.fullPay === true
        this.$set(this.paymentList[this.moneyindex], 'fullPay', true)
        // console.log(obj.fullPay)
        obj.payNowStr = obj.unpaidamt
        this.calculationData()
      }
    },
    chargeBtn(action, done) {
      // 确认or取消
      if (action === 'cancel') { // 取消按钮
        this.paymentList[this.moneyindex].fullPay = true
        this.calculationData()
        done()
      } else if (action === 'confirm') { // 确定按钮
        if (this.money === '.') {
          Toast.fail('输入金额格式有误')
          done(false)
          return false
        }
        if (this.money * 100 >= this.paymentList[this.moneyindex].unpaidamt) {
          Toast.fail(this.$t('msg.amountOver'))
          done(false)
          return false
        }
        // 向后端传值并关闭dialog弹出框
        this.paymentList[this.moneyindex].payNowStr = this.money
        this.calculationData()
        this.$set(this.paymentList[this.moneyindex], 'fullPay', false)
        // this.paymentList[this.moneyindex].mustPay = false
        this.show = false
        done()
      }
    },
    // // 获取数据
    // getWaitingPayList() {
    //   for (let i = 0; i < this.paymentList.length; i++) {
    //     this.$set(this.paymentList[i], 'fullPay', true)
    //     if (this.paymentList[i].payNowStr === null) {
    //       this.paymentList[i].payNowStr = this.paymentList[i].unpaidamt
    //     }
    //     if (this.paymentList[i].mustPay === '1') {
    //       this.paymentList[i].show = true
    //       this.$set(this.paymentList[i], 'mustSpan', '必缴')
    //     } else {
    //       this.paymentList[i].show = false
    //       this.$set(this.paymentList[i], 'mustSpan', '选缴')
    //     }
    //   }
    // },
    // 获取数据
    getWaitingPayList() {
      this.loading = true
      setTimeout(() => {
        saveUserGetStandNameList(
          window.localStorage.getItem('accountNum'),
          window.localStorage.getItem('schoolCode')
        ).then(response => {
          this.paymentList = response.data
          this.loading = false
          Toast.success(this.$t('msg.loadSuccess'))
          for (let i = 0; i < this.paymentList.length; i++) {
            this.paymentList[i].show = false
            this.$set(this.paymentList[i], 'fullPay', true)
            if (this.paymentList[i].payNowStr === null) {
              this.paymentList[i].payNowStr = this.paymentList[i].unpaidamt
            }
            if (this.paymentList[i].mustPay === '1') {
              this.paymentList[i].show = true
              this.$set(this.paymentList[i], 'mustSpan', '必缴')
            } else {
              this.paymentList[i].show = false
              this.$set(this.paymentList[i], 'mustSpan', '选缴')
            }
          }
        }).catch(error => {
          Toast.fail(error.message)
          this.loading = false
        })
      }, 500)
    },
    nextStep() {
      const payList = []
      for (let i = 0; i < this.paymentList.length; i++) {
        if (this.paymentList[i].show === true) {
          payList.push(this.paymentList[i])
        }
      }
      const paymentList = JSON.parse(JSON.stringify(payList))
      for (let j = 0; j < paymentList.length; j++) {
        if (paymentList[j].payNowStr >= paymentList[j].unpaidamt) {
          paymentList[j].payNowStr = null
        }
      }
      var flag = this.checkRules(paymentList)
      if (flag === false) {
        return false
      }
      // 判断projectId,如果多个项目切projectId不同，则提示不可提交
      this.projectIdList = []
      for (var i of this.paymentList) {
        if (i.show) {
          this.projectIdList.push(i)
        }
      }
      Dialog.alert({
        title: '提示',
        message: '是否支付',
        showCancelButton: true
      }).then(() => {
        this.nextLoading = true
        closeOrderByIdserialAndProjectCode(paymentList[0].idserial, window.localStorage.getItem('schoolCode'), paymentList[0].projectCode,).then(res => {
          submitNextStep(paymentList).then(response => {
            var order = response.data.PayOrderTrade
            this.$router.push({ name: 'person', query: { projectId: this.paymentList[0].projectId, amount: this.total, order: order, orderId: order.id }})
            this.nextLoading = false
          }).catch(err => {
            Toast.fail(err.message)
            this.nextLoading = false
          })
        }).catch(err => {
          Toast.fail(err.message)
          this.nextLoading = false
        })
      })
    },
    checkRules(payList) {
      var errorFlag = true
      var list = payList
      if (list.length < 1) {
        Toast.fail(this.$t('msg.selectPaid'))
        return false
      }
      // 如果部分缴费 判断是否输入金额为空
      list.forEach(item => {
        if (item.fullPay === false) {
          if (item.payNowStr === null || item.payNowStr === '' || item.payNowStr === undefined) {
            errorFlag = false
          }
        }
      })
      if (errorFlag === false) {
        Toast.fail(this.$t('msg.partPaid'))
        return false
      }
      return true
    },
    // 返回
    onClickLeft() {
      this.$router.go(-1)
      // this.$router.go(-1)
    },
    // 带参数跳转页面
    dealwith(item) {
      this.$router.push({
        path: '/person'
      })
    },
    // 金钱转换
    fenToYuan(val) {
      return fenToYuan(val)
    },
    // 计算选中的数据
    calculationData() {
      this.total = 0
      this.count = 0
      this.allAmount = 0
      this.sectionAmount = 0
      for (let i = 0; i < this.paymentList.length; i++) {
        const allPay = []
        const sectionPay = []
        if (this.paymentList[i].show === true) {
          if (this.paymentList[i].fullPay === false) {
            sectionPay.push(this.paymentList[i])
            for (let j = 0; j < sectionPay.length; j++) {
              this.sectionAmount += Number(sectionPay[j].payNowStr)
            }
          } else {
            allPay.push(this.paymentList[i])
            for (let k = 0; k < allPay.length; k++) {
              this.allAmount += Number(allPay[k].payNowStr)
            }
          }
          this.count += 1
        }
      }
      this.allAmount = fenToYuan(this.allAmount)
      this.total = Number(this.sectionAmount) + Number(this.allAmount)
    }
  }
}
</script>
<style scoped lang="scss">
#electricityFee {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 86px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .van-switch {
    font-size: 24px;
    height: 22px;
    line-height: 24px;
  }
  .van-cell__value {
    height: 24px;
  }
}
.outboxstyle {
  padding-top: 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
  min-height: 100px;
}
.boxstyle {
  font-size:16px;
  height:50px;
  background:#fff;
  border-radius:10px 10px 0px 0px;
  padding:0px 30px
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 999;
  padding: relative;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .round-tag{
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 100;
  }
  .van-tag--danger{
    background-color: #FF4444;
  }
}
.bottombutton{
  margin: 10px 20px;
  width:90%;
  height: 40px;
}
.van-cell__value {
  span{
    color: #333333;
  }
}
#totalpay {
  .van-cell__value {
  span{
    color: #3BA4F2;
  }
}
}
.group {
  padding: 0 0 10px;
  border-radius: 10px;
  margin: 5px 12px;
  border:1px solid #3BA4F2
}
.group2 {
  padding: 0 0 10px;
  border-radius: 10px;
  margin: 5px 12px;
  border:1px solid #eeeeee
}
.line{
  width:40px;
  position:absolute;
  right: -1px;
  top: -1px;
  z-Index:2;
}
.total{
  font-size:13px;
  font-weight:550;
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 4px;
  img{
    align-items: center;
    margin:0 8px 0 16px;
    width:40px;
  }
}
.total-text{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.total-status{
      font-size: 11px;
    width: 50px;
    height: 16px;
    line-height: 16px;
    background: rgba(58,132,218,0.1);
    border-radius: 4px;
    text-align: center;
    font-weight: 400;
    color: rgb(59, 164, 242);
}
</style>
<style lang="scss">
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
.van-switch__node {
  width: 20px !important;
  height: 20px !important;
  left: 2px;
}
#listLoading .van-loading{
    position: fixed !important;
    color: #c8c9cc !important;
    font-size: 0;
    vertical-align: middle;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    background: #333333;
    opacity: 0.8;
    border-radius: 10px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
#listLoading .van-loading__spinner {
      color: rgb(255, 255, 255);
      width: 40px !important;
      height: 40px !important;
      margin: 20px !important;
  }
</style>
